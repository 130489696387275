<template>
  <div class="view-wrapper">
    <div class="view">
      <v-card>
        <v-card-title style="padding: 4px 16px">
          <h6>Фильтр</h6>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-row dense>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="filter.from"
                  label="С"
                  type="date"
                  required
                  @keydown.enter="fetchItems"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="filter.to"
                  label="По"
                  type="date"
                  required
                  @keydown.enter="fetchItems"
                ></v-text-field>
              </v-col>
                <v-col cols="12" md="2">
                  <v-autocomplete
                    v-model="filter.underwriterId"
                    :items="ctx.src.org.byType('1')"
                    :label="'Страховая'"
                    item-text="fullName"
                    item-value="id"
                    clearable
                    @keydown.enter="fetchItems"
                  >
                  </v-autocomplete>
                </v-col>
              <v-col cols="12" md="2">
                <v-autocomplete
                  v-model="filter.organizationId"
                  :items="ctx.src.org.byType('2')"
                  :label="'Клиентская'"
                  item-text="fullName"
                  item-value="id"
                  clearable
                  @keydown.enter="fetchItems"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="2">
                <v-autocomplete
                  v-model="filter.city"
                  :items="ctx.src.dict['INS_CITY'].items"
                  :label="'Город'"
                  item-text="value"
                  item-value="value"
                  clearable
                  @keydown.enter="fetchItems"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="2">
                <v-checkbox
                  v-model="filter.enableCity"
                  :label="'Показать город'"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="2">
                <v-checkbox
                  v-model="filter.enableClientId"
                  :label="'Показать клиентскую'"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" small @click="fetchItems">
            Применить
          </v-btn>
          <v-btn small @click="resetFilter">
            Сбросить
          </v-btn>
          <v-spacer />
          <v-btn
            color="green"
            :loading="exporting"
            :disabled="items.length === 0"
            small
            class="white--text"
            @click="exp"
          >
            Экспорт
            <v-icon right dark>
              cloud_download
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-data-table

        :headers="headers"
        :items="items"
        :items-per-page="-1"
        :single-select="true"
        item-key="id"
        hide-default-footer
        :loading="loading"
        loading-text="Загрузка... Пожалуйста подождите"
        fixed-header
      >
        <template #item.index ="{item}">
          {{ items.indexOf(item) + 1 }}
        </template>
<!--        <template v-slot:item.reportingDateLocal="{ item }">-->
<!--          <span>{{ item.reportingDateLocal | dateLocalFilter }}</span>-->
<!--        </template>-->
      </v-data-table>
    </div>
  </div>
</template>

<script>
import ctx from "@/ctx";
import fetch from "@/utils/fetch";
import js2vm from "@/utils/js2vm";
import dateIsoFilter from "@/filters/dateIso.filter";
// import dateChangeFilter from "@/filters/dateChange.filter";
import vm2js from "@/utils/vm2js";

let Filter = function() {
  let self = this;

  let from = new Date();
  from.setDate(from.getDate() - 30);
  self.from = dateIsoFilter(from);
  self.to = dateIsoFilter(new Date());
  self.city = undefined;
  self.organizationId = undefined;
  self.underwriterId = undefined;
  self.enableCity = false;
  self.enableClientId = false;
};

let Item = function(d) {
  let self = this;
  d.value = d.city;
  d.reportingDateLocal = d.reportingDate;
  d.organization = d.organizationId;
  d.underwriter = d.underwriterId;
  d.quantity1 = d.quantity;
  d.detached = d.newInsured;

  self.organization2vm = function(val) {
    if (!val) return {};
    let org = ctx.src.org.first(val);
    if (org.cityKey) org.city = ctx.src.dict["INS_CITY"].get(org.cityKey).value;
    return org;
  };
  self.underwriter2vm = function(val) {
    if (!val) return {};
    return ctx.src.org.first(val);
  };


  js2vm(self, d);
};
// eslint-disable-next-line no-unused-vars
function groupArrayOfObjects(list, key) {
  return list.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}


let Report = function(d) {
  let self = this;

  self.title = d.title;
  self.subtitle = d.subtitle;
  self.organizationId = d.organization;
  self.underwriterId = d.underwriter;
  self.rows = [];

  d.rows.forEach(i => {
    self.rows.push(new Item(i));
  });
};
export default {
  data: () => ({
    ctx,
    selected: [],
    loading: false,
    divide: [
      { key: "1", label: "Год" },
      { key: "2", label: "День" },
      { key: "3", label: "Квартал" },
      { key: "4", label: "Месяц" }
    ],
    headers: [
      {
        text: "№",
        align: "start",
        sortable: false,
        value: "index"
      },
      {
        text: "Страховая",
        sortable: false,
        value: "underwriter.fullName"
      },
      {
        text: "Клиентская",
        sortable: false,
        value: "organization.fullName"
      },
      {
        text: "Город",
        sortable: false,
        value: "value"
      },
      {
        text: "Количество общее",
        sortable: false,
        value: "quantity1"
      },

      {
        text: "Кол-во новых",
        sortable: false,
        value: "detached"
      },

      {
        text: "Кол-во ВМС",
        sortable: true,
        value: "vmsCount"
      }
    ],
    report: {},
    render: function (createElement) {
      return createElement(
        'h' + this.level,   // имя тега
        this.$slots.default // массив дочерних элементов
      )
    },
    props: {
      level: {
        type: Number,
        required: true
      }
    },
    filter: new Filter(),
    items: [],
    exporting: false
  }),
  methods: {
    resetFilter() {
      this.filter = new Filter();
    },
    async exp() {
      this.exporting = true
      const res = await fetch.post(
        "/api/report/by-combined-total-client/export",
        vm2js(this.report)
      );
      if (res) {
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(await res.blob);
        link.download = res.filename;
        link.click();
      }
      this.exporting = false
    },
    async fetchItems() {
      this.loading = true;
      this.items = [];
      let res = await fetch.post(
        "/api/report/by-combined-total-client/get",
        vm2js(this.filter)
      );
      if (res) {
        this.report = new Report(res);
        res.rows.forEach(r => {
          this.items.push(new Item(r));
        });
      }
      this.loading = false;
    },
  },
  watch: {
    ['filter.city']() {
      if (this.filter.city) {
        this.filter.enableCity = true;
      } /*else {
        this.filter.enableCity = false;
      }*/
    },
    ['filter.organizationId']() {
      if (this.filter.organizationId) {
        this.filter.enableClientId = true;
      } /*else {
        this.filter.enableClientId = false;
      }*/
    },
    ['filter.enableCity']() {
      if (!this.filter.enableCity) {
        this.filter.city = undefined;
      }
    },
    ['filter.enableClientId']() {
      if (!this.filter.enableClientId) {
        this.filter.organizationId = undefined;
      }
    }
  }
};
</script>
